import * as React from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { readableColor } from 'polished'
import 'typeface-work-sans'
import { Box, Flex } from '../elements'
import theme from '../../config/theme'
import reset from '../styles/reset'
import logo from '../favicon.png'
import { FaYoutube, FaLinkedin, FaBars, FaInstagram, FaTiktok, FaGlobe, FaLink } from "react-icons/fa";
import { MdClose } from "react-icons/md";
{/*import Logo from './logo'*/ }

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const GlobalStyles = createGlobalStyle`
  [data-block-id] {
    max-width: 100%!important;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::selection {
    color: white;
    background-color: #4dc4e2;
  }

  html {
    box-sizing: border-box;
    border: 0;
    margin: 0;

    h1, h2, h3, h4, h5, h6 {
      font-weight: ${theme.fontWeights.bold};
    }
    
    .logoImg {
      width: 100%;
      height: 100%;
    }

    p {
      letter-spacing: -0.003em;
      --baseline-multiplier: 0.179;
      --x-height-multiplier: 0.35;
      line-height: 1.58;
      

    }
    p.legal{
        font-size: 12px;
      }
    }

    ol {
      letter-spacing: -0.003em;
      --baseline-multiplier: 0.179;
      --x-height-multiplier: 0.35;
      line-height: 1.58;
    }

    ul {
      letter-spacing: -0.003em;
      --baseline-multiplier: 0.179;
      --x-height-multiplier: 0.35;
      line-height: 1.58;
    }

    li {
      padding-bottom:5px
    }
    
    h1 {
      font-size: ${theme.fontSizes[5]};
    }
    h2 {
      font-size: ${theme.fontSizes[4]};
    }
    h3 {
      font-size: ${theme.fontSizes[3]};
    }
    h4 {
      font-size: ${theme.fontSizes[2]};
    }
    h5 {
      font-size: ${theme.fontSizes[1]};
    }
    h6 {
      font-size: ${theme.fontSizes[0]};
    }
    
    @media (max-width: 600px) {
      font-size: 16px;
      
      h1 {
        font-size: ${theme.fontSizes[4]};
      }
      h2 {
        font-size: ${theme.fontSizes[3]};
      }
      h3 {
        font-size: ${theme.fontSizes[2]};
      }
      h4 {
        font-size: ${theme.fontSizes[1]};
      }
      h5 {
        font-size: ${theme.fontSizes[0]};
      }
      h6 {
        font-size: ${theme.fontSizes[0]};
      }
    }
  }
  .seva-form{
    
  margin: 0 20px 40px;
  transition: all 0.5s ease;
  :hover {
    box-shadow: rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px;
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.02);
  }
  }

  .post-card-img{
    object-fit: cover;
  }
  .homePosts{
    @media (min-width: 795px) {
    .post-card:nth-of-type(6n + 1):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
      max-height: 425px;
    }
    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1;
      border-radius: 5px 0 0 5px;
    }
    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image {
      postition: absolute;
      width: 100%;
      height: 100%;
    }
    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }
    .post-card:nth-of-type(6n + 1):not(.no-image) h4 {
      font-size: 1.7rem;
    }
    .post-card:nth-of-type(6n + 1):not(.no-image) p {
      font-size: 1.2rem;
      line-height: 1.55em;
    }
    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }
    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
    }
  }

  .iframe-container{
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }
  .iframe-container-full{
    padding-top: 35%;
    position: relative;
    overflow: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    height: auto;
  }
  .iframe-container-full::-webkit-scrollbar{ 
    width: 0px;
  }
  
  input {
    &:focus {
      outline: none;
    }
    box-sizing: border-box;
    color: #2a2a2a;
    width: 100%;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 1000rem;
  }

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  body {
    border: 0;
    margin: 0;
    padding: 0;
    color: black;
    font-family: 'Work Sans', '-apple-system', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background: white;
    font-size: 18px;
  }
  a {
    transition: all 0.3s ease-in-out;
    color: black;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: ${theme.colors.primary};
    } 
  }
  mark { 
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, #191F28 50%);
    color: white;
    :hover {
      color: #2592AE;
    }
  }
  markl { 
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, #D9EFFC 50%);
    color: black;
    :hover {
      color: #2592AE;
    }
  }
  .icon{
    display: flex;
    align-items: center;
    border: 0;
    margin-right: 10px;
    margin-bottom: 3px;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    min-width: 140px;
    background-color: black;
    color: #fff;
    text-align: center;
    font-size: 18px;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-bottom: 30px;
    margin-left: -63px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  ${reset}
`

const isPartiallyActive = ({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) =>
  isPartiallyCurrent ? { className: 'navlink-active navlink' } : { className: 'navlink' }

const PartialNavLink = ({ children, to, ...rest }: { children: React.ReactNode; to: string }) => (
  <Link getProps={isPartiallyActive} to={to} {...rest}>
    {children}
  </Link>
)

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.sidebarWidth.big} 1fr;
  @media (max-width: ${props => props.theme.breakpoints[4]}) {
    grid-template-columns: ${props => props.theme.sidebarWidth.normal} 1fr;
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
  }
`

const SideBarInner = styled(Box) <{ bg: string }>`
  position: fixed;
  height: 100%;
  width: ${props => props.theme.sidebarWidth.big};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  background: ${props => props.bg};

  @media (max-width: ${props => props.theme.breakpoints[4]}) {
    width: ${props => props.theme.sidebarWidth.normal};
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    position: relative;
    width: 100%;
  }

  svg {
    fill: ${props => readableColor(`${props.bg}`)};
  }
`

const Nav = styled(Flex) <{ color: string }>`
  a {
    padding: 2px 0px;
    text-decoration: none;
    color: ${props => readableColor(`${props.color}`)};
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: 1.5;
    &:hover,
    &:focus,
    &.navlink-active {
      color: ${props => readableColor(`${props.color}`)};
    }

    &:hover::after,
    &:focus::after,
    &.navlink-active::after {
      content: ' •';
    }

    &:hover::before,
    &:focus::before,
    &.navlink-active::before {
      content: '• ';
    }

    @media (max-width: ${props => props.theme.breakpoints[2]}) {
      font-size: ${props => props.theme.fontSizes[2]};
      margin-left: ${props => props.theme.space[4]};
    }

    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      font-size: ${props => props.theme.fontSizes[1]};
      margin-left: ${props => props.theme.space[3]};
      display: none;
    }

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      font-size: ${props => props.theme.fontSizes[0]};
      margin-left: ${props => props.theme.space[2]};
    }
  }
`

const MobileNav = styled(Flex) <{ color: string }>`
    color: ${props => readableColor(`${props.color}`)};
    display: visible;
    @media (min-width: ${props => props.theme.breakpoints[1]}) {
     display: none;
    }
  }
`

const MobileNavList = styled(Flex) <{ color: string }>`
gap: 20px;
  a {
    padding: 2px 0px;
    text-decoration: none;
    color: ${props => readableColor(`${props.color}`)};
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: 1.5;
    &:hover,
    &:focus,
    &.navlink-active {
      color: ${props => readableColor(`${props.color}`)};
    }

    &:hover::after,
    &:focus::after,
    &.navlink-active::after {
      content: ' •';
    }

    &:hover::before,
    &:focus::before,
    &.navlink-active::before {
      content: '• ';
    }
  }
`

const Main = styled.main`
@media (min-width: calc(${props => props.theme.breakpoints[2]} + 1px)) {
  grid-column-start: 2;
}
@supports (-ms-ime-align:auto) {
  @media (min-width: ${props => props.theme.breakpoints[2] + 1}px) {
    grid-column-start: 2;
  }
}
`

const Footer = styled.footer<{ color: string }>`
  position: fixed;
  width: ${props => props.theme.sidebarWidth.big};
  bottom: 0;

  background: ${props => props.color};

  color: ${props => readableColor(`${props.color}`, `${props.theme.colors.grey}`, '#c3c3c3')};

  a {
    color: ${props => readableColor(`${props.color}`)};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
  
  .tooltip .tooltiptext {
    color: ${props => (props.color)};
    background-color: ${props => readableColor(`${props.color}`)};
    text-decoration: none;
  }

  .tooltip .tooltiptext::after {
    border-color: ${props => readableColor(`${props.color}`)} transparent transparent transparent;
  }

  @media (max-width: ${props => props.theme.breakpoints[4]}) {
    width: ${props => props.theme.sidebarWidth.normal};
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    position: relative;
    width: 100%;
  }
`
const MobileNavOverlay = styled.div<{ color: string }>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${props => props.color};
  z-index: 99;
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: none;
  }
`

const Title = styled(Link)`
  display: flex;
  align-items: center; // Centers items vertically
  gap: 10px; // Adds a gap between flex items, you can adjust the value as needed
  text-decoration: none;
`;

const Name = styled.h3<{ color: string }>`
  color: ${props => readableColor(`${props.color}`)};
  
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    font-size: ${props => props.theme.fontSizes[2]};
  }

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[1]};
  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[0]};
  }
`

type LayoutProps = { children: React.ReactNode } & typeof defaultProps

const defaultProps = {
  color: 'white',
}

interface QueryResult {
  navigation: {
    nodes: {
      name: string
      link: string
    }[]
  }
}

const Layout = ({ children, color }: LayoutProps) => {
  const data: QueryResult = useStaticQuery(query)

  const { navItems, cta } = data
  const [isOpen, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "visible"
    }
  }, [isOpen])

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Wrapper>
          <SideBarInner bg={color} as="aside" p={[6, 6, 8]}>
            <Flex
              flexWrap="nowrap"
              flexDirection={['row', 'row', 'row', 'column']}
              alignItems={['center', 'center', 'center', 'flex-start']}
              justifyContent="space-between"
            >
              <Box width={['3rem', '4rem', '5rem', '6rem']}>
                  {/*<Logo />*/}
                  <Title to="/" aria-label="Conrad Lin, Back to Home">
                    <img className="logoImg" src={logo} alt="Conrad Lin" />
                    <Name color={color}>Conrad Lin 👋</Name>
                </Title>
              </Box>
              <Nav
                color={color}
                mt={[0, 0, 0, 10]}
                as="nav"
                flexWrap="nowrap"
                flexDirection={['row', 'row', 'row', 'column']}
                alignItems="flex-start"
              >
                {data.navigation.nodes.map(item => (
                  <PartialNavLink to={item.link} key={item.name}>
                    {item.name}
                  </PartialNavLink>
                ))}
              </Nav>
              <MobileNav
                color={color}>
                <FaBars size={30}
                  title="Toggle menu"
                  onClick={() => setOpen(!isOpen)}
                />
              </MobileNav>
            </Flex>
          </SideBarInner>
          <Main>
            {children}</Main>
          <Footer color={color}>
            <Box p={[6, 6, 8]} fontSize={0}>
              {/*<h5><Link to="/subscribe/">Subscribe 😄</Link></h5>*/}
              <div className="icon">                {/* <a href="https://www.youtube.com/conradlin" className="icon" target="_blank"><FaYoutube size={30} /></a>
                <a href="https://www.tiktok.com/@conradlin" className="icon" target="_blank"><FaTiktok size={30} /></a>
                <a href="https://instagram.com/levelupwithconrad" className="icon" target="_blank"><FaInstagram size={30} /></a> */}
                {/*<Link className="icon" to="/subscribe/"><span className="tooltip"><span className="tooltiptext">Subscribe 😄</span></span><MdEmail size={30}/></Link>
              */}
                {/* <a href="https://www.linkedin.com/in/conradlin/" className="icon" target="_blank"><FaLinkedin size={25} /></a> */}
                {/*<Link className="icon"to="/reading-list/"><FaBook size={24}/></Link>*/}
              </div>
              <Link to="/info">Made with ♥.</Link> © {new Date().getFullYear()}
              <a href="https://conradl.in?utm_source=conradlin.com" className="icon" target="_blank" style={{ border: '1px solid #ccc', borderRadius: '8px', marginTop: '10px', padding: '10px', display: 'inline-flex', alignItems: 'center', gap: '10px' }}><FaLink size={20} />Connect With Me</a>
              <p className="legal"><Link to="/terms-of-use">Terms of Use</Link> | <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/disclaimer">Disclaimer</Link></p>
            </Box>
          </Footer>
          {isOpen && (
            <MobileNavOverlay color={color}>
              <div>
                <SideBarInner bg={color} as="aside" p={[6, 6, 8]}>
                  <Flex
                    flexWrap="nowrap"
                    flexDirection={['row', 'row', 'row', 'column']}
                    alignItems={['center', 'center', 'center', 'flex-start']}
                    justifyContent="space-between"
                  >
                    <Box width={['3rem', '4rem', '5rem', '6rem']}>
                      <Link to="/" aria-label="Conrad Lin, Back to Home">
                        {/*<Logo />*/}
                        <img className="logoImg" src={logo} alt="Conrad Lin" />
                      </Link>
                    </Box>
                    <MobileNav
                      color={color}>
                      <MdClose size={30}
                        title="Toggle menu"
                        onClick={() => setOpen(!isOpen)}
                      />
                    </MobileNav>
                  </Flex>
                </SideBarInner>
                <MobileNavList
                  color={color}
                  as="nav"
                  flexWrap="nowrap"
                  flexDirection={['column']}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {data.navigation.nodes.map(item => (
                    <PartialNavLink to={item.link} key={item.name}>
                      {item.name}
                    </PartialNavLink>
                  ))}
                </MobileNavList>
              </div>
            </MobileNavOverlay>
          )}
        </Wrapper>
      </>
    </ThemeProvider>
  )
}

export default Layout

Layout.defaultProps = defaultProps

const query = graphql`
  query Layout {
    navigation: allNavigationYaml {
      nodes {
        name
        link
      }
    }
  }
`
